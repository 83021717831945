import React from "react";

const Background = (props) => {
    const {currentQuestionIndex, questions} = props;
    let i = 0;
    const image = (question) => {
        let imageClass = 'step__bg';
        let backgroundUrl = '';
        let imageFolder = parseFloat(currentQuestionIndex) + 1;
        if (currentQuestionIndex === i || currentQuestionIndex - 1 === i || currentQuestionIndex + 1 === i) {
            if (currentQuestionIndex - 1 === i) {
                imageClass = 'step__bg prev-image';
                imageFolder -= 1;
            }
            if (currentQuestionIndex === i) {
                imageClass = 'step__bg active';
            }
            if (currentQuestionIndex + 1 === i) {
                imageClass = 'step__bg next-image';
                imageFolder += 1;
            }
            backgroundUrl = "url(/images/" + imageFolder + '/' + "img.jpg)";
        }
        i++;
        return <div key={question.question} className={imageClass}
                    style={{backgroundImage: backgroundUrl}}></div>
    }

    return questions.map((question) =>
        image(question)
    );
}

export default Background;