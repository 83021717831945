import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle';
import axios from 'axios';

import { Settings } from '../store';
import quizQuestions from '../questions.json'
import ResultsFinalScreen from "./ResultsFinalScreen";
import Header from "./Header";

const ResultById = () => {
    const params = useParams();

    const settingsState = React.useContext(Settings.State)
    const { language } = settingsState;

    const questions = quizQuestions[language].results;
    const averageQuizScore = quizQuestions[language].average_score;

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [mainCategories, setMainCategories] = useState([]);
    const [answersFromAPIResponse, setAnswersFromAPIResponse] = useState([]);

    const getQuestions = () => {
        const { id } = params;
        axios.get(`${process.env.REACT_APP_QUIZ_GET_URL}${id}`)
            .then((questions) => {
                const allQuestions = questions.data.data.attributes.answers;
                setIsLoaded(true);
                setAnswersFromAPIResponse(allQuestions);
                document.body.classList.add('is-loaded');

            })
            .catch(function (error) {
                setError(error);
                console.log(error);
            })
    }

    // Do this on first load of this page
    useEffect(() => {
        getQuestions();
    }, []);

    // Do this everytime the language change since we use the language in here
    // to generate the main categories
    useEffect(() => {
        let tempArray = [];
        quizQuestions[language].results.forEach((category) => {
            if (tempArray.indexOf(category.main_cat) === -1) {
                tempArray.push(category.main_cat);
            }
        });
        setMainCategories(tempArray);
    }, [language, quizQuestions])


    if (error) {
        return (
            <>
                <Header/>
                <div style={{color: '#fff', padding: 100}}>Error: {error.message}</div>
            </>
        );
    }

    if (!isLoaded) {
        return (
            <div className="preloader loading">
                <div className="dot-flashing"></div>
            </div>
        );
    }

    return <ResultsFinalScreen
        answersFromAPIResponse={answersFromAPIResponse}
        questions={questions}
        lang={language}
        mainCategories={mainCategories}
        averageQuizScore={averageQuizScore}
    />;
}

export default ResultById;
