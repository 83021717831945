import React, {Component, useEffect, useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle';
import {Settings} from '../store';
import Question from './Question';
import Header from './Header';
import Answers from './Answers';
import Results from './Results';
import Background from './Background';
import Modal from './Modal';
import axios from 'axios';
import quizQuestions from '../questions.json';

const imagesLoaded = require('imagesloaded');

const QuizWrapper = props => {
    const settingsState = useContext(Settings.State);
    const {language} = settingsState;

    // Because react router don't like us to call navigate when we mount
    // components, we send the Quiz component a setState function instead
    // which we in turn can listen to using useEffect in this component.
    // It's not ideal, but seems to work fine and doesn't require us to
    // rewrite the whole quiz component.
    //
    // The quiz components update this wrappers component state variable
    // "url", and when it does, using useEffect we call on `navigate`
    // to do the actual navigation.
    const [url, setUrl] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (url) {
            navigate(url);
        }
    }, [url]);

    return (
            <Quiz
                    lang={language}
                    navigate={setUrl}
                    {...props}
            />
    );
};

class Quiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            questions: quizQuestions[props.lang].results,
            currentQuestionIndex: 0,
            lastQuestion: false,
            correctAnswersCounter: 0,
            showAnswersList: true,
            userAnswer: '',
            showCurrentQuestion: true,
            showAnswerStatistic: false,
            showResults: false,
            respondFromUser: false,
            mainCategories: [],
            lockHorizontalLine: true,
            backgroundLoaded: false,
            userEmail: '',
            answers: [],
            showFinalScreen: false,
            answersFromAPIResponse: [],
            fadeLastQuestion: false,
            showErrorScreen: false,
            lang: props.lang,
            quizUrl: '/quiz/',
        };
        this.getQuestions = this.getQuestions.bind(this);
        this.handleClickedAnswer = this.handleClickedAnswer.bind(this);
        this.prepareQustion = this.prepareQustion.bind(this);
        this.handleUserAnswer = this.handleUserAnswer.bind(this);
        this.handleScreenAnimations = this.handleScreenAnimations.bind(this);
        this.changeQuestionObject = this.changeQuestionObject.bind(this);
        this.prevQuestion = this.prevQuestion.bind(this);
        this.setQuizQuestionUrl = this.setQuizQuestionUrl.bind(this);
        this.sendDataToAPI = this.sendDataToAPI.bind(this);
        this.answerExist = this.answerExist.bind(this);
        this.addUserAnswer = this.addUserAnswer.bind(this);
    }

    componentDidMount() {
        this.getQuestions();
        let self = this;

        //back button in browser
        window.onpopstate = e => {
            e.preventDefault();
            if (self.state.currentQuestionIndex === 0) {
                return false;
            }
            if (self.state.showFinalScreen) {
                self.props.navigate('/');
                return false;
            }
            if (self.state.showResults) {
                self.prevQuestion();
                self.setState({showResults: false});
                self.setQuizQuestionUrl(self.state.currentQuestionIndex);
                self.setState({
                    respondFromUser: false,
                    showAnswerStatistic: false,
                    showCurrentQuestion: false,
                    userAnswer: '',
                    fadeLastQuestion: false,
                });
            } else if (self.state.currentQuestionIndex !== 0) {
                self.prevQuestion();
            } else {
                self.props.navigate('/');
            }
            return false;
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lang !== this.props.lang) {
            this.setState({
                questions: quizQuestions[this.props.lang].results,
                lang: this.props.lang
            });
        }
        if (prevState.questions !== this.state.questions) {
            this.getQuestions();
        }
    }

    componentWillUnmount() {
        window.onpopstate = e => {
            return false;
        };
    }

    sendDataToAPI(email, phone) {
        const {answers, lang} = this.state;
        let self = this;
        let postApi = process.env.REACT_APP_QUIZ_POST_URL;
        let dataToSend = {
            'data': {
                'type': 'lead-quiz-submissions',
                'attributes': {
                    'email': email,
                    'answers': answers,
                    'country': lang,
                    'phone': phone,
                },
            },
        };

        axios.post(postApi, dataToSend).then((response) => {
            if (response.status === 201) {
                self.setState({
                    showFinalScreen: true,
                    answersFromAPIResponse: response.data.data.attributes.answers,
                });
            }
        }).catch(function(error) {
            self.setState({
                error: error,
            });
            console.log(error);
        });
    }

    getQuestions() {
        let tempArray = [];
        let self = this;

        for (let question in this.state.questions) {
            if (tempArray.indexOf(this.state.questions[question].main_cat) === -1) {
                tempArray.push(this.state.questions[question].main_cat);
            }
        }

        this.setQuizQuestionUrl(0);

        this.setState({
            isLoaded: true,
            averageQuizScore: quizQuestions[this.state.lang].average_score,
            mainCategories: tempArray,
        });


        imagesLoaded('.step__bg.active', {background: true}, function() {
            if (self.state.currentQuestionIndex === 0) {
                setTimeout(() => {
                    self.setState({
                        backgroundLoaded: true,
                    });
                    document.body.classList.add('is-loaded');
                }, 500);

            }
        });
    }

    changeQuestionObject(index, res) {
        return {
            questions: this.state.questions[index].answerResult = res,
        };
    }

    correctAnswer(index) {
        return this.state.questions[index].correct_answer;
    }

    answerExist(questionID) {
        let answers = this.state.answers;
        return answers.filter(function(e) {
            return e.questionId === questionID;
        }).length > 0;
    }

    addUserAnswer(questionID, userAnswer, answerType) {
        let answerValue = false;
        let joined = this.state.answers;
        let self = this;
        if (userAnswer === this.correctAnswer(questionID)) {
            answerValue = true;
        }
        if (answerType) {
            answerValue = null;
        }

        if (this.answerExist(this.state.questions[questionID].id)) {
            joined = this.state.answers.filter(function(e) {
                return e.questionId !== self.state.questions[questionID].id;
            });
        }
        var a = joined, b = [{'questionId': this.state.questions[questionID].id, 'answer': answerValue}];
        this.setState({answers: a.concat(b)});
    }

    handleUserAnswer(currentQuestionIndex, userAnswer, answerType) {

        const {correctAnswersCounter, questions} = this.state;
        let trueOrFalse;
        questions[currentQuestionIndex].user_answer = userAnswer;
        if (userAnswer === this.correctAnswer(currentQuestionIndex)) {
            this.setState({correctAnswersCounter: correctAnswersCounter + 1});
            trueOrFalse = true;
        } else {
            trueOrFalse = false;
            if (answerType) {
                questions[currentQuestionIndex].irelevant = true;
            }
        }
        let questionID = currentQuestionIndex;

        this.addUserAnswer(questionID, userAnswer, answerType);
        questions[currentQuestionIndex].answerResult = trueOrFalse;
    }

    setQuizQuestionUrl(currentQuestionIndex) {
        let str;
        if (currentQuestionIndex === this.state.questions.length) {
            str = 'result';
        } else {
            str = this.state.questions[currentQuestionIndex].category.replace(/\s+/g, '-').toLowerCase();
        }
        const url = `${this.state.quizUrl}${str}`;
        this.props.navigate(url);
    }

    handleScreenAnimations(index) {
        if (index + 1 === this.state.questions.length) {
            this.setState({fadeLastQuestion: true});
            setTimeout(() => {
                this.setState({showResults: true});
            }, 300);

            return false;
        }
        if (this.state.questions[index + 1].image !== '' && this.state.questions[index + 1].image !== undefined) {
            this.setState({image: this.state.questions[index + 1].image});
        }
        this.setState({showAnswersList: false});
        this.setState({userAnswer: ''});
        this.setState({showCurrentQuestion: false});
        this.setState({currentQuestionIndex: index + 1});
        this.setState({showAnswersList: true});
        this.setState({showCurrentQuestion: true});
        this.setState({showAnswerStatistic: false});
        this.setState({respondFromUser: false});
    }

    handleClickedAnswer(e) {
        const {currentQuestionIndex} = this.state;
        let answerObject = e.target;
        let customAnswer = answerObject.getAttribute('data-custom');
        let additionalAnswer = answerObject.getAttribute('data-additional');
        let irelevantAnswer = answerObject.getAttribute('data-irelevant') === 'true';
        let answerToDisplay = answerObject.value;
        let changeQuestionSpeed = 2200;
        let changeScreenSpeed = 2700;
        if (irelevantAnswer) {
            changeQuestionSpeed = 100;
            changeScreenSpeed = 500;
        }
        let self = this;
        setTimeout(function() {
            self.setState({respondFromUser: true});
        }, changeQuestionSpeed);

        if (!irelevantAnswer) {
            if (additionalAnswer != '') {
                answerToDisplay = answerObject.value + ' - ' + additionalAnswer;
            }
            if (customAnswer != '') {
                answerToDisplay = customAnswer;
            }
            this.setState({showAnswerStatistic: true});
            this.setState({userAnswer: answerToDisplay});
        }

        this.handleUserAnswer(currentQuestionIndex, answerObject.value, irelevantAnswer);
        this.prepareQustion(currentQuestionIndex, changeScreenSpeed);
    }

    prepareQustion(index, changeQuestionSpeed) {
        setTimeout(() => {
            this.handleScreenAnimations(index);
            this.setQuizQuestionUrl(index + 1);
        }, changeQuestionSpeed);
    }

    quizProgress = (currentQuestionIndex, questions) => {
        if (currentQuestionIndex === 0) {
            return 0;
        } else {
            return (currentQuestionIndex) / (questions.length - 1) * 100;
        }
    };

    prevQuestion() {

        const {currentQuestionIndex, correctAnswersCounter, showResults} = this.state;
        let prevQuestionIndex = currentQuestionIndex - 1;

        if (showResults) {
            prevQuestionIndex = currentQuestionIndex;
        }

        if (currentQuestionIndex !== 0) {
            this.setState({currentQuestionIndex: prevQuestionIndex});

            if (this.state.questions[prevQuestionIndex].answerResult === true) {
                this.setState({correctAnswersCounter: correctAnswersCounter - 1});
            }
        }
        this.setQuizQuestionUrl(prevQuestionIndex);
    }

    render() {
        const {
            error,
            isLoaded,
            currentQuestionIndex,
            questions,
            userAnswer,
            showAnswersList,
            showCurrentQuestion,
            correctAnswersCounter,
            showResults,
            respondFromUser,
            averageQuizScore,
            mainCategories,
            backgroundLoaded,
            showFinalScreen,
            answersFromAPIResponse,
            fadeLastQuestion,
            lang,
            answers,
        } = this.state;

        if (error) {
            return <div style={{color: '#fff'}}>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div className="preloader loading">
                <div className="blur"></div>
            </div>;
        } else {
            let blockClass = 'row chat__options justify-content-center flex-column align-items-center';
            if (this.state.showAnswerStatistic) {
                blockClass += ' active';
            }
            if (showResults) {
                return <Results
                        mainCategories={mainCategories} averageQuizScore={averageQuizScore}
                        questions={questions}
                        correctAnswersCounter={correctAnswersCounter}
                        sendDataToAPI={this.sendDataToAPI}
                        showFinalScreen={showFinalScreen}
                        answersFromAPIResponse={answersFromAPIResponse}
                        lang={lang}
                        answers={answers}
                />;
            } else {
                return (<>
                    <div className={fadeLastQuestion ? 'animate__animated animate__fadeOut' : ''}>
                        <div className={backgroundLoaded ? 'preloader loaded' : 'preloader loading'}>
                            <div className="dot-flashing"></div>
                        </div>
                        <div className={respondFromUser === true ? 'activeScreen wrapper' : 'inactiveScreen wrapper'}>
                            <Header
                                    showBackButton={currentQuestionIndex !== 0 && true}
                                    prevQuestion={this.prevQuestion}
                                    quizProgress={this.quizProgress(currentQuestionIndex, questions)}
                                    currentQuestionIndex={currentQuestionIndex}
                                    questionsLength={questions.length}
                                    lang={lang}
                            />
                            <main className={backgroundLoaded ? 'chat loaded' : 'chat'}>
                                <div className="step">
                                    <div className="container">
                                        <Question showCurrentQuestion={showCurrentQuestion} userAnswer={userAnswer}
                                                  quesiton={questions[currentQuestionIndex]}/>
                                        <div
                                                className={blockClass}>
                                            <Answers respondFromUser={respondFromUser}
                                                     showAnswerStatistic={this.state.showAnswerStatistic}
                                                     showAnswersList={showAnswersList}
                                                     handleClickedAnswer={this.handleClickedAnswer}
                                                     answers={questions[currentQuestionIndex]}
                                                     currentQuestionIndex={currentQuestionIndex}
                                                     lang={lang}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="image-container">
                                    <Background questions={questions} currentQuestionIndex={currentQuestionIndex}
                                                backgroundLoaded={backgroundLoaded}/>
                                </div>
                            </main>
                        </div>
                        <Modal currentQuestionIndex={currentQuestionIndex} questions={questions}/>
                    </div>
                </>);
            }
        }
    };
}

export default QuizWrapper;
