import React from "react";
import translation from "../lang.json";

export const getFinalScoreString = (score, average, lang) => {

    let translationObject = translation[lang].result_page;

    if (score < average) {
        return translationObject.score_down + ' ' + average + ' %';
    }
    if (score > average) {
        return translationObject.score_up + ' ' + average + ' %';
    }
    return translationObject.score_equal + ' ' + average + ' %';
}