import React, {useContext, useEffect, useState} from 'react';
import validator from 'validator';
import translation from '../lang.json';
import {Settings} from '../store';
import TagManager from 'react-gtm-module';

const EmailSubmissionModal = (props) => {
    const {lang} = props;
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [consentCheckboxError, setConsentCheckboxError] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [consentCheckbox, setConsentCheckbox] = useState(false);
    const [consentPhone, setConsentPhone] = useState(false);

    let translationObject = translation[lang].result_page.email_screen;
    const validateCheckboxConsent = (consentCheckbox) => {
        if (!consentCheckbox) {
            setConsentCheckboxError(translationObject.checkboxVerification);
        } else {
            setConsentCheckboxError('');
        }
    };

    const validateCheckboxPhone = (phone) => {
        if (lang === 'dk') {
            if (phone !== '') {
                setConsentPhone(true);
            } else {
                setConsentPhone(false);
                setPhoneError('');
            }
        }
    };

    const validateFields = () => {

        let phoneValidation = validator.isMobilePhone(phone);
        let emailValidation = validator.isEmail(email);

        if (!phoneValidation) {
            setPhoneError(translationObject.phone_notification);
        } else {
            setPhoneError('');
        }

        if (!emailValidation) {
            setEmailError(translationObject.email_notification);
        } else {
            setEmailError('');
        }
        validateCheckboxConsent(consentCheckbox);
        validateCheckboxPhone(phone);

        if (lang === 'dk' && phone === '') {
            phoneValidation = true;
        }

        return phoneValidation && emailValidation && consentCheckbox;

    };

    const submitForm = (e) => {
        e.preventDefault();
        if (validateFields()) {
            setSpinner(true);
            TagManager.dataLayer({
                dataLayer: {
                    userProject: 'Quiz',
                    event: 'formSubmission',
                    formType: 'Question collection',
                    formName: 'Quiz submission',
                    formCategory: 'Results collection',
                },
            });
            props.sendDataToAPI(email, phone);
        } else {
            setSpinner(false);
        }

    };
    return (<>
                <div style={{display: 'block'}} className="modal bg-blur" id="email-modal" tabIndex="-1"
                     aria-labelledby="email-modalLabel" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                     aria-hidden="true">
                    <div
                            className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-body d-flex flex-column gap-4">
                                <div className="text-white text-center mb-2">
                                    <h2 className="mb-3">{translationObject.heading}</h2>
                                    <div style={{margin: '0 auto'}}>
                                        <p className="preamble">{translationObject.subheading}</p>
                                    </div>
                                </div>

                                <form className="form text-white d-flex flex-column gap-3" action="">
                                    <div className="">
                                        <label htmlFor="userEmail"
                                               className="form-label visually-hidden">{translationObject.email_address_label}</label>
                                        <input onChange={(e) => setEmail(e.target.value)} type="email" id="userEmail"
                                               className="form-control"
                                               placeholder={translationObject.input_text} required/>
                                        <div id="emailHelp"
                                             className="text-white mt-2 form-text me-auto">{emailError}</div>
                                    </div>
                                    <div className="">
                                        <label htmlFor="userPhone"
                                               className="form-label visually-hidden">{translationObject.phone_label}</label>
                                        <input onChange={(e) => {
                                            setPhone(e.target.value);
                                            validateCheckboxPhone(e.target.value);
                                            // if (e.target.value !== '' && lang === 'dk') {
                                            //     setConsentPhone(true);
                                            // } else {
                                            //     setConsentPhone(false);
                                            //     setPhoneError('');
                                            // }
                                        }} type="text" id="userPhone" className="form-control"
                                               placeholder={translationObject.phone_label} required/>
                                        <div className="text-white mt-2 form-text me-auto">{phoneError}</div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="form-check">
                                            <input onChange={(e) => {
                                                setConsentCheckbox(!consentCheckbox);
                                                // if (!e.target.checked) {
                                                //     setConsentCheckboxError(translationObject.checkboxVerification);
                                                // } else {
                                                //     setConsentCheckboxError('');
                                                // }
                                                validateCheckboxConsent(e.target.checked);
                                            }} className="form-check-input" type="checkbox" value=""
                                                   id="consentCheckbox" required/>
                                            <label className="form-check-label small" htmlFor="consentCheckbox"
                                                   dangerouslySetInnerHTML={{__html: translationObject.consentCheckbox}}>
                                            </label>
                                        </div>
                                        <div className="text-white mt-2 form-text me-auto">{consentCheckboxError}</div>
                                    </div>
                                    <div className="mb-3" style={{display: !consentPhone && 'none'}}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value=""
                                                   id="phoneCheckChecked" checked disabled/>
                                            <label className="form-check-label" htmlFor="phoneCheckChecked">
                                                Ja jeg vil gerne kontaktes telefonisk vedrørende nyheder og anden
                                                information med markedsføringsrelateret om vedrørende Formues
                                                rådgivnings-/tjenesteydelser.
                                            </label>
                                        </div>

                                    </div>

                                    <div className="d-grid">
                                        <input type="submit" disabled={spinner && 'disabled'}
                                               className={spinner ? 'd-none btn btn-primary' : 'btn btn-primary'}
                                               value={translationObject.button}
                                               onClick={submitForm}/>
                                        <div
                                                className={spinner ? 'btn btn-primary p-4 position-relative' : 'd-none btn btn-primary p-4 position-relative'}>
                                            <div className="dot-flashing"></div>
                                        </div>
                                    </div>
                                    {/*<div className="form-text text-white mt-4 opacity-75"*/}
                                    {/*     dangerouslySetInnerHTML={{__html: translationObject.description}}></div>*/}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show"></div>
            </>
    );
};

export default EmailSubmissionModal;
