import React from "react";
import translation from '../lang.json';

const QuestionMark = (props) => {
    return (
        <>
            {(() => {
                if (props.popupContent !== undefined && props.popupContent !== '') {
                    return (<>
                            <div className="col-auto w-100 text-center">
                                <div
                                    className={props.respondFromUser === true ? 'pb-3 animate__animated animate__delay-1s' : 'pb-3 animate__animated animate__zoomIn animate__delay-1s'}>
                                    <button className="chat-info btn btn-dark text-center rounded-pill"
                                            type="button" data-bs-toggle="modal"
                                            data-bs-target="#formuemodal">
                                        ?
                                    </button>

                                </div>
                            </div>
                        </>
                    )
                }
            })()
            }
            <div className="col-auto w-100 text-center">
                <div className="chat-annotation animate__animated animate__fadeIn">
                    <div className="countdown">
                        <div className="circle"></div>
                        {/*<div className="clock" style={circle}>*/}
                        {/*</div>*/}
                    </div>
                    <p className="text-white">
                        {translation[props.lang].answers.other_answered}
                    </p>
                </div>
            </div>
        </>);
}

export default QuestionMark;