import Header from "./Header";
import React from "react";
import translation from '../lang.json';
const imagesLoaded = require('imagesloaded');

const ResultBanner = (props) => {
    const {scoreString, score, showBackButton, lang} = props;

    imagesLoaded('.banner', {background: true}, function () {
        setTimeout(function () {
                document.body.classList.add('is-loaded');
            },
            500);
    });

    return (<div className="banner d-flex text-white"
                 style={{background: "url('/images/results.jpg') calc(50%) center / cover no-repeat"}}>
        <Header lang={lang} showBackButton={showBackButton}/>
        <div className="container-fluid">
            <div className="row flex-column h-100 justify-content-center align-items-center">
                <div className="col-xl-7 col-sm-8 text-center py-5">
                    <div className="banner__content">
                        <h2>{translation[lang].result_page.banner_pretitle1}</h2>
                        <h4>{translation[lang].result_page.banner_pretitle2}</h4>
                        <h1 className="score pt-5">{score}<span>%</span></h1>
                        {/*<p className="preamble pt-5 pb-3 mb-5 px-5">{scoreString}</p>*/}
                        <p className="mt-3 mt-lg-5 pt-5">{translation[lang].result_page.banner_title}</p>
                        <a target={'_blank'} href={'https://formue.' + lang + '/kontakt/'}
                           className="btn btn-light">{translation[lang].result_page.banner_button}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default ResultBanner;
