import {useContext, useEffect} from "react";
import {Settings} from '../store';

export const useLanguageDetector = () => {
    const dispatch = useContext(Settings.Dispatch)
    useEffect(() => {
        if (!dispatch) {
            return null;
        }

        // Determine the language of the quiz. We try to be smart about it by checking the
        // domain we are hosted at. If we are hosted at at .se domain, we use swedish language
        // if we are hosted at .no we use norwegian language. One can also override by using
        // a query param ?language=no in the url

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        if (params.language) {
            dispatch({type: 'setLanguage', payload: params.language.toLowerCase()});
        } else {
            const {hostname} = window.location;
            const hostnameParts = hostname.split('.');
            switch (hostnameParts[hostnameParts.length - 1]) {
                case 'no': {
                    dispatch({type: 'setLanguage', payload: 'no'});
                    break;
                }
                case 'se': {
                    dispatch({type: 'setLanguage', payload: 'se'});
                    break;
                }
                case 'dk': {
                    dispatch({type: 'setLanguage', payload: 'dk'});
                    break;
                }
                default: {
                    dispatch({type: 'setLanguage', payload: 'no'});
                    break;
                }
            }
        }
    }, []);
    return null;
}