import React from "react";
import Answer from "./Answer";
import QuestionMark from "./QuestionMark";

const Answers = (props) => {

    const {all_answers, popup_content} = props.answers;

    const answers = all_answers.map((answer) =>
        <Answer showAnswerStatistic={props.showAnswerStatistic} handleClickedAnswer={props.handleClickedAnswer}
                key={answer.title + props.currentQuestionIndex} answer={answer} lang={props.lang}/>
    );
    let blockClass = 'hide down';
    if (props.showAnswersList === true) {
        blockClass = 'show';
    }
    return (
        <>
            <div className={blockClass}>
                <QuestionMark key={props.currentQuestionIndex} popupContent={popup_content} respondFromUser={props.respondFromUser} lang={props.lang}/>
                {answers}
            </div>
        </>
    )
}

export default Answers;