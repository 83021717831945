import React from "react";
import ResultColumn from "./ResultColumn";
import IrelevantResult from "./IrelevantResult"
import ResultBanner from "./ResultBanner";
import translation from '../lang.json';
import {getFinalScore} from '../helpers/getFinalScore';
import {getFinalScoreString} from '../helpers/getFinalScoreString';

const ResultsFinalScreen = (props) => {
    const {
        questions,
        answersFromAPIResponse,
        lang,
        averageQuizScore,
        mainCategories
    } = props;

    let translationObject = translation[lang].result_page;
    let finalScore = getFinalScore(answersFromAPIResponse);
    let scoreString = getFinalScoreString(finalScore, averageQuizScore, lang);

    if (!answersFromAPIResponse.length) {
        return 'Something went wrong, try again later';
    }
    const irelevantQuestions = answersFromAPIResponse.filter(e => e.answer === null).map((question) => {
            let questionToPass = questions.find(obj => {
                return obj.id === question.questionId;
            })
            return <IrelevantResult key={question.questionId} question={questionToPass}/>;
        }
    );

    const resultColumns = mainCategories.map((category) => {
            return <ResultColumn key={category} answersFromAPIResponse={answersFromAPIResponse} questions={questions}
                                 category={category} lang={lang}/>;
        }
    );
    document.body.classList.add('results-screen');
    return (
        <div className="results">
            <ResultBanner lang={lang} scoreString={scoreString} score={finalScore} showBackButton={false}/>
            <div className="py-5 text-center">
                <div className="container">
                    <h4 className="py-xl-5 px-4">{translationObject.headingmain}</h4>
                </div>
            </div>
            <div className="pb-5">
                <div className="container">
                    <div className="row">
                        {resultColumns}
                    </div>
                    {(() => {
                        if (irelevantQuestions.length) {
                            return (<>
                                    <div className="mt-4 py-5">
                                        <hr/>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <h4 className="mb-4  text-center">{translationObject.irelevant}</h4>
                                            <div className="category-result p-3">
                                                {irelevantQuestions}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    })()}
                </div>
            </div>
        </div>
    )
}
export default ResultsFinalScreen;
