import React, {useState} from "react";
import translation from "../lang.json";

const Answer = (props) => {

    function isInArray(value, array) {
        return array.indexOf(value) > -1;
    }

    const {title, custom_answer, additional_answer} = props.answer;

    let percent = 0;
    let percentClass = 'chat-item chat-item--a chat-item__text chat-item--r';
    let showStatistic = props.showAnswerStatistic && props.answer.percent != 0;
    let activeButton = false;
    let titleText = title;
    let yesNoArray = translation[props.lang].yes_no_array;
    if (showStatistic) {
        percent = props.answer.percent;
        percentClass += ' percent';
        activeButton = true;
        titleText = title.substr(0, title.indexOf(','));
        if (!isInArray(titleText, yesNoArray)) {
            titleText = title;
        }
    }
    const [isActive, setActive] = useState(false);
    const toggleClass = () => {
        setActive(!isActive);
    };
    return (<div className="chat__options__item col-auto w-100 text-center">
        <div className="p-1 text-center">
            <div onClick={toggleClass}
                 className={isActive ? 'active ' + percentClass + ' mx-auto' : percentClass + ' mx-auto'}
                 data-irelevant={props.answer.percent === 0}>
                <span style={{width: percent + '%'}} className="progress-bar"></span>
                <span className="percent-item">{showStatistic ? percent + ' % - ' + titleText : titleText}</span>
                <button disabled={activeButton}
                        data-percent={percent}
                        value={title}
                        className="answer-button"
                        data-custom={custom_answer}
                        data-irelevant={props.answer.percent === 0}
                        data-additional={additional_answer}
                        onClick={props.handleClickedAnswer}>
                </button>
            </div>
        </div>
    </div>);

}

export default Answer;