import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './App.scss';
import App from './App';
import { Store } from './store';

ReactDOM.render(
    <BrowserRouter>
        <Store>
            <App />
        </Store>
    </BrowserRouter>,
  document.getElementById('formue-quiz')
);