import React from "react";
import EmailSubmissionModal from "./EmailSubmissionModal";
import ResultBanner from "./ResultBanner";

const ResultsEmailScreen = (props) => {
    const {scoreString, score, sendDataToAPI,lang} = props;

    return (<>
        <div className="wrapper results no-email position-relative animate__animated animate__fadeIn">
            <ResultBanner scoreString={scoreString} score={score} showBackButton={false} lang={lang}/>
        </div>
        <EmailSubmissionModal sendDataToAPI={sendDataToAPI} lang={lang}/>
    </>);
}

export default ResultsEmailScreen;
