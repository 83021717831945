import React from "react";
import Result from "./Result";
import translation from '../lang.json';

const ResultColumn = (props) => {
    const {category, questions, answersFromAPIResponse,lang} = props;

    const results = answersFromAPIResponse.filter(e => e.answer != null).sort((a, b) => (a.answer === b.answer) ? 0 : a.answer ? 1 : -1).map((question) => {
            let questionToPass = questions.find(obj => {
                return obj.id === question.questionId;
            })

            if (questionToPass.main_cat === category && question.answer != null) {

                return <Result key={question.questionId} correctAnswer={question.answer} question={questionToPass} category={category}/>;
            }
        }
    );
    let translationObject = translation[lang].categories
    let icon, description = '';
    switch (category) {
        case 'Trygghet':
        case 'Tryghed':
                icon = '<svg width="40" height="40" viewBox="0 0 40 40" fill="none"\n' +
                '                 xmlns="http://www.w3.org/2000/svg">\n' +
                '                <path\n' +
                '                    d="M38.416 19H35.415C35.143 19 34.927 18.782 34.918 18.5105C34.659 10.5585 28.109 4.16901 20.095 4.16901C12.081 4.16901 5.53149 10.5585 5.27249 18.5105C5.26349 18.782 5.04749 19 4.77549 19H1.77449C1.49449 19 1.26349 18.769 1.27099 18.489C1.54299 8.34101 9.88299 0.169006 20.095 0.169006C30.307 0.169006 38.6475 8.34101 38.919 18.489C38.927 18.769 38.696 19 38.416 19Z"\n' +
                '                    fill="#A0496D"/>\n' +
                '                <path\n' +
                '                    d="M16.25 40C13.2655 40 10.8045 37.714 10.526 34.8015C10.498 34.5065 10.7305 34.25 11.0265 34.25H14.059C14.2965 34.25 14.4915 34.4195 14.546 34.6505C14.728 35.423 15.423 36 16.25 36C17.874 36 18 33.895 18 33.25V17.861C18 17.585 18.224 17.361 18.5 17.361H21.5C21.776 17.361 22 17.585 22 17.861V33.25C22 37.8875 19.0195 40 16.25 40Z"\n' +
                '                    fill="#F2637B"/>\n' +
                '            </svg>';
            description = translationObject.trygghet;
            break;
        case 'Bevare & utvikle':
        case 'Bevara & Utveckla':
        case 'Bevare og udvikle':
                icon = '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path d="M34.8775 0.212493H34.8825C35.0525 0.221493 35.184 0.367493 35.175 0.536993L35.0865 2.23499C35.0755 2.44649 35.066 2.68149 35.056 2.93549C34.9325 5.97649 34.703 11.637 30.987 15.025C29.016 16.822 26.3915 17.681 23.1825 17.5795L23.152 17.578C22.982 17.569 22.8505 17.4215 22.8595 17.2515L23.0355 13.856C23.0445 13.686 23.1905 13.5555 23.3605 13.5645L23.376 13.565C25.4975 13.622 27.104 13.127 28.2795 12.055C30.2685 10.2415 30.7765 6.97849 30.9525 4.47549L30.9535 4.45399C30.966 4.28449 30.8375 4.13549 30.668 4.12299H30.655C27.447 4.25899 24.9045 5.07949 23.2235 6.53549C21.5765 7.96199 21.1425 9.64299 21.029 10.4835L21.021 10.5465C21.012 10.7165 20.862 10.848 20.692 10.839L17.2985 10.663C17.1285 10.654 16.997 10.508 17.006 10.3385L17.007 10.2875C17.1455 8.84199 17.7865 5.92799 20.5925 3.49799C23.502 0.977993 27.738 -0.157007 33.184 0.124993L34.8775 0.212493Z" fill="#6ECA5B"/>\n' +
                '<path d="M8.86897 15.768C8.69897 15.7645 8.55697 15.901 8.55397 16.071L8.55297 16.091C8.60097 18.596 8.94497 21.9055 10.8305 23.8125C11.9525 24.947 14.0255 25.5245 16.157 25.574L16.165 25.5735C16.335 25.5735 16.474 25.713 16.474 25.883V29.2845C16.474 29.4545 16.335 29.594 16.165 29.594L16.1485 29.5935C12.932 29.531 9.85047 28.5375 7.97247 26.638C4.43647 23.062 4.49997 17.397 4.53397 14.354C4.53697 14.1 4.53947 11.9605 4.53947 11.9605V11.9525C4.53947 11.7825 4.67847 11.6435 4.84847 11.6435H4.85197H6.54897C12.002 11.6435 16.174 12.996 18.949 15.6635C21.871 18.472 22.1865 21.7225 22.1865 22.99V39.6085C22.1865 39.7785 22.0475 39.9175 21.8775 39.9175H18.477C18.307 39.9175 18.168 39.7785 18.168 39.6085V22.9895C18.168 22.402 18.0235 20.3475 16.1645 18.5605C14.5645 17.0225 12.0745 16.0725 8.88697 15.7685L8.86897 15.768Z" fill="#567E59"/>\n' +
                '</svg>\n';
            description = translationObject.bevare;
            break;
        case 'Skape':
        case 'Skapa':
        case 'Skabe':
                icon = '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path d="M11.8445 28.557C11.605 28.6975 11.2936 28.615 11.1556 28.3735C8.53155 23.782 7.21705 19.684 7.16055 19.5065C6.84255 18.4335 6.26355 16.038 6.26355 14.384C6.26355 6.46749 12.4395 0.0269928 20.0306 0.0269928C27.6215 0.0269928 33.7976 6.46749 33.7976 14.384C33.7976 15.824 33.5936 17.246 33.1911 18.61L33.1641 18.694C33.0956 18.8935 31.5095 23.4865 29.328 28.0355C29.208 28.2855 28.906 28.3895 28.657 28.2675L25.962 26.95C25.715 26.8295 25.6136 26.5325 25.732 26.2845C27.7015 22.172 29.2061 17.898 29.3671 17.435C29.6525 16.4515 29.7976 15.4255 29.7976 14.384C29.7976 8.67299 25.416 4.02699 20.0306 4.02699C14.6451 4.02699 10.2635 8.67299 10.2635 14.384C10.2635 15.331 10.63 17.136 10.9845 18.334C10.9855 18.336 12.2185 22.162 14.611 26.3585C14.747 26.597 14.669 26.9005 14.4325 27.0395L11.8445 28.557Z" fill="#7C599F"/>\n' +
                '<path d="M26.514 33.9655H14.014C13.738 33.9655 13.514 33.7415 13.514 33.4655V30.4655C13.514 30.1895 13.738 29.9655 14.014 29.9655H26.514C26.79 29.9655 27.014 30.1895 27.014 30.4655V33.4655C27.014 33.7415 26.79 33.9655 26.514 33.9655Z" fill="#C76FF2"/>\n' +
                '<path d="M24.7665 39.904H15.7665C15.4905 39.904 15.2665 39.68 15.2665 39.404V36.404C15.2665 36.128 15.4905 35.904 15.7665 35.904H24.7665C25.0425 35.904 25.2665 36.128 25.2665 36.404V39.404C25.2665 39.68 25.043 39.904 24.7665 39.904Z" fill="#59497A"/>\n' +
                '</svg>\n';
            description = translationObject.skape;
            break;
        default:

    }

    return (
        <>
            <div className="col-lg-4 text-center mb-5">
                <div className="heading px-4">
                    <div dangerouslySetInnerHTML={{__html: icon}}></div>
                    <h4 className="pt-3 pb-1">{category}</h4>
                    <p className="small">{description}</p>
                </div>
                <div className="category-result p-3 mt-5">
                    {results}
                </div>
            </div>
        </>
    )
}
export default ResultColumn;
