import React from "react";

const UserAnswer = (props) => {

    let blockClass = 'user-answer response col animate__animated animate__fadeInUp';
    if (props.userAnswer === '') {
        blockClass = 'response col d-none';
    }

    return (
        <div className={blockClass}>
            <div className="pb-3">
                <div
                    className="chat-item ms-auto chat-item--r">
                    <div className="chat-item__text">
                       {props.userAnswer}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserAnswer;