import React from "react";
import {useEffect, useState} from "react";
//import TagManager from './tag-manager';
import TagManager from 'react-gtm-module';


export const usePageTracking = () => {

    const [initialized, setInitialized] = useState(false);
    const hostnameParts = window.location.hostname.split('.');
    const trackingID = 'GTM-MST5FSJ';

    let country = 'Norway';
    if (hostnameParts[hostnameParts.length - 1] === 'se') {
        country = 'Sweden';
    }

    if (hostnameParts[hostnameParts.length - 1] === 'dk') {
        country = 'Danmark';
    }

    const tagManagerArgs = {
        gtmId: trackingID,
        dataLayer: {
            userProject: 'Quiz',
            event: 'metadata',
            pageCategory :'Quiz',
            country: country
        }
    }
    useEffect(() => {

        if (!initialized) {
            TagManager.initialize(tagManagerArgs);
            setInitialized(true);
        }

    }, [initialized]);

};