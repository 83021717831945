import React from "react";

const IrelevantResult = (props) => {
    const {category, to_do, id, popup_content} = props.question;
    let popupExist = popup_content !== undefined && popup_content !== '';
    return (
        <>
            <div className="single true irelevant px-3 py-4" data-dismiss="modal" data-bs-toggle="modal"
                 data-bs-target={'#popup' + id} role={popupExist ? 'button' : ''}>
                <div className="d-flex">
                    <span className="checkbox"></span>
                    <div className="ms-3 text-start w-100">
                        <span className="category-title">{category}</span>
                        <div className="d-flex align-items-center flex-grow-1">
                            <p className="m-0 pe-2">{to_do}</p>
                            <svg className="ms-auto" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.24584 14.265C4.57251 14.5917 5.09917 14.5917 5.42584 14.265L10.9658 8.725C11.2258 8.465 11.2258 8.045 10.9658 7.785L5.42584 2.245C5.09917 1.91833 4.57251 1.91833 4.24584 2.245C3.91917 2.57167 3.91917 3.09833 4.24584 3.425L9.07251 8.25833L4.23917 13.0917C3.91917 13.4117 3.91917 13.945 4.24584 14.265Z"
                                    fill="#2F3A71"/>
                            </svg>
                        </div>
                    </div>
                </div>
                {(() => {
                    if (popupExist) {
                        return (<>
                                <div className="modal fade" id={'popup' + id} tabIndex="1"
                                     aria-labelledby={'popup' + id}
                                     aria-hidden="true" data-backdrop="">
                                    <div
                                        className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg modal-fullscreen-md-down">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body"
                                                 dangerouslySetInnerHTML={{__html: popup_content}}></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                })()
                }
            </div>
        </>
    )
}
export default IrelevantResult;