import React from "react";
import { Route, Routes } from "react-router-dom";

import { useLanguageDetector } from './services/useLanguageDetector';
import Home from "./components/Home";
import Quiz from "./components/Quiz";
import ResultByID from "./components/ResultByID";
import NotFound from "./components/NotFound";
import {usePageTracking} from "./services/usePageTracking";

const App = () => {
    useLanguageDetector();
    usePageTracking();

    return (
        <Routes>
            <Route path="/" element={<Home />}/>

            <Route path="/quiz/" element={<Quiz />}/>

            <Route path="/quiz/*" element={<Quiz />}/>

            <Route path="/results/:id" element={<ResultByID />}/>
            
            <Route path="*" element={<NotFound/>} />
        </Routes>
    )
}

export default App;