import React, {useState, useContext,useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Settings} from '../store';
import Header from "./Header";
import translation from '../lang.json';
import TagManager from 'react-gtm-module';

const imagesLoaded = require('imagesloaded');

const Home = () => {
    const [prepareQuiz, setPrepareQuiz] = useState(false);
    const [homeImage, setHomeImage] = useState("url('/images/index.jpg') calc(45%) center / cover no-repeat");

    const quizUrl = '/quiz/';
    const navigate = useNavigate();
    const startQuizWithDelay = (e) => {
        e.preventDefault();

        TagManager.dataLayer({
            dataLayer: {
                userProject: 'Quiz',
                event: 'CTAevent',
                CTAaction: 'Start quiz button'
            }
        });
        setPrepareQuiz(true);
        setTimeout(() => {
            navigate(quizUrl);
            document.body.classList.remove('is-loaded');
        }, 500);
        return true;
    }
    const settingsState = useContext(Settings.State)
    const {language} = settingsState;
    const translationObject = translation[language].home_page

    useEffect(() => {
       if(language === 'dk') {
           setHomeImage("url('/images/index-dk.jpg') calc(45%) center / cover no-repeat")
       }
    }, [language]);


    imagesLoaded('.background', {background: true}, function () {
        setTimeout(function () {
            document.body.classList.add('is-loaded');
        }, 100);
    });

    return (
        <>
            <div className="start h-100 animate__animated animate__fadeIn animate__slower">
                <div className="container-fluid h-100">
                    <Header lang={language}/>
                    <div
                        className="row flex-column h-100 justify-content-center align-items-center text-center text-white">
                        <div
                            className={prepareQuiz ? 'background animate__animated animate__fadeOut' : 'background'}
                            style={{background: homeImage}}></div>
                        <div
                            className={prepareQuiz ? 'col-xl-7 col-sm-8 animate__animated animate__fadeOutUpSmall' : 'col-xl-7 col-sm-8 start__content'}>

                            <div className="mb-5">
                                <h1>{translationObject.title}</h1>
                            </div>

                            <div>
                                <Link onClick={startQuizWithDelay} to="/quiz"
                                      className="btn btn-light">{translationObject.button}
                                </Link>
                            </div>

                            <div className="mt-3 text-center">
                                <button className="btn btn-link text-white" type="button" data-bs-toggle="modal"
                                        data-bs-target="#infomodal" onClick={() => {
                                    TagManager.dataLayer({
                                        dataLayer: {
                                            userProject: 'Quiz',
                                            event: 'CTAevent',
                                            CTAaction: 'See good plan - under start quiz button'
                                        }
                                    });
                                }}>{translationObject.info}</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="infomodal" tabIndex="-1" aria-labelledby="infomodal"
                 aria-hidden="true">
                <div
                    className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg modal-fullscreen-md-down">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h6 className="modal-title mb-3">{translationObject.popup.title}</h6>
                            <h4 className="mb-5">{translationObject.popup.descp}</h4>
                            <div className="d-flex align-items-start justify-content-start mb-3">
                                <div className="me-2 mt-n1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M23.0498 11.3999H21.2492C21.086 11.3999 20.9564 11.2691 20.951 11.1062C20.7956 6.33502 16.8656 2.50132 12.0572 2.50132C7.2488 2.50132 3.3191 6.33502 3.1637 11.1062C3.1583 11.2691 3.0287 11.3999 2.8655 11.3999H1.0649C0.896902 11.3999 0.758302 11.2613 0.762802 11.0933C0.926002 5.00452 5.93 0.101318 12.0572 0.101318C18.1844 0.101318 23.1887 5.00452 23.3516 11.0933C23.3564 11.2613 23.2178 11.3999 23.0498 11.3999Z"
                                            fill="#A0496D"/>
                                        <path
                                            d="M9.75023 23.9999C7.95953 23.9999 6.48293 22.6283 6.31583 20.8808C6.29903 20.7038 6.43853 20.5499 6.61613 20.5499H8.43563C8.57813 20.5499 8.69513 20.6516 8.72783 20.7902C8.83703 21.2537 9.25403 21.5999 9.75023 21.5999C10.7246 21.5999 10.8002 20.3369 10.8002 19.9499V10.7165C10.8002 10.5509 10.9346 10.4165 11.1002 10.4165H12.9002C13.0658 10.4165 13.2002 10.5509 13.2002 10.7165V19.9499C13.2002 22.7324 11.4119 23.9999 9.75023 23.9999Z"
                                            fill="#F2637B"/>
                                    </svg>
                                </div>
                                <div>
                                    <h5>{translationObject.popup.tryghhet.title}</h5>
                                    <p>{translationObject.popup.tryghhet.descp}</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start justify-content-start mb-3">
                                <div className="me-2 mt-n1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M20.9263 0.127578H20.9293C21.0313 0.132978 21.1102 0.220578 21.1048 0.322278L21.0517 1.34108C21.0451 1.46798 21.0394 1.60898 21.0334 1.76138C20.9593 3.58598 20.8216 6.98228 18.592 9.01508C17.4094 10.0933 15.8347 10.6087 13.9093 10.5478L13.891 10.5469C13.789 10.5415 13.7101 10.453 13.7155 10.351L13.8211 8.31368C13.8265 8.21168 13.9141 8.13338 14.0161 8.13878L14.0254 8.13908C15.2983 8.17328 16.2622 7.87628 16.9675 7.23308C18.1609 6.14498 18.4657 4.18718 18.5713 2.68538L18.5719 2.67248C18.5794 2.57078 18.5023 2.48138 18.4006 2.47388H18.3928C16.468 2.55548 14.9425 3.04778 13.9339 3.92138C12.9457 4.77728 12.6853 5.78588 12.6172 6.29018L12.6124 6.32798C12.607 6.42998 12.517 6.50888 12.415 6.50348L10.3789 6.39788C10.2769 6.39248 10.198 6.30488 10.2034 6.20318L10.204 6.17258C10.2871 5.30528 10.6717 3.55688 12.3553 2.09888C14.101 0.586878 16.6426 -0.0941217 19.9102 0.0750783L20.9263 0.127578Z"
                                            fill="#6ECA5B"/>
                                        <path
                                            d="M5.32131 9.46078C5.21931 9.45868 5.13411 9.54058 5.13231 9.64258L5.13171 9.65458C5.16051 11.1576 5.36691 13.1433 6.49821 14.2875C7.17141 14.9682 8.41521 15.3147 9.69411 15.3444L9.69891 15.3441C9.80091 15.3441 9.88431 15.4278 9.88431 15.5298V17.5707C9.88431 17.6727 9.80091 17.7564 9.69891 17.7564L9.68901 17.7561C7.75911 17.7186 5.91021 17.1225 4.78341 15.9828C2.66181 13.8372 2.69991 10.4382 2.72031 8.61238C2.72211 8.45998 2.72361 7.17628 2.72361 7.17628V7.17148C2.72361 7.06948 2.80701 6.98608 2.90901 6.98608H2.91111H3.92931C7.20111 6.98608 9.70431 7.79758 11.3693 9.39808C13.1225 11.0832 13.3118 13.0335 13.3118 13.794V23.7651C13.3118 23.8671 13.2284 23.9505 13.1264 23.9505H11.0861C10.9841 23.9505 10.9007 23.8671 10.9007 23.7651V13.7937C10.9007 13.4412 10.814 12.2085 9.69861 11.1363C8.73861 10.2135 7.24461 9.64348 5.33211 9.46108L5.32131 9.46078Z"
                                            fill="#567E59"/>
                                    </svg>
                                </div>
                                <div>
                                    <h5>{translationObject.popup.bevare.title}</h5>
                                    <p>{translationObject.popup.bevare.descp}</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start justify-content-start">
                                <div className="me-2 mt-n1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.10641 17.1341C6.96271 17.2184 6.77581 17.1689 6.69301 17.024C5.11861 14.2691 4.32991 11.8103 4.29601 11.7038C4.10521 11.06 3.75781 9.62271 3.75781 8.63031C3.75781 3.88041 7.46341 0.0161133 12.018 0.0161133C16.5726 0.0161133 20.2782 3.88041 20.2782 8.63031C20.2782 9.49431 20.1558 10.3475 19.9143 11.1659L19.8981 11.2163C19.857 11.336 18.9054 14.0918 17.5965 16.8212C17.5245 16.9712 17.3433 17.0336 17.1939 16.9604L15.5769 16.1699C15.4287 16.0976 15.3678 15.9194 15.4389 15.7706C16.6206 13.3031 17.5233 10.7387 17.6199 10.4609C17.7912 9.87081 17.8782 9.25521 17.8782 8.63031C17.8782 5.20371 15.2493 2.41611 12.018 2.41611C8.78671 2.41611 6.15781 5.20371 6.15781 8.63031C6.15781 9.19851 6.37771 10.2815 6.59041 11.0003C6.59101 11.0015 7.33081 13.2971 8.76631 15.815C8.84791 15.9581 8.80111 16.1402 8.65921 16.2236L7.10641 17.1341Z"
                                            fill="#7C599F"/>
                                        <path
                                            d="M15.9084 20.3792H8.4084C8.2428 20.3792 8.1084 20.2448 8.1084 20.0792V18.2792C8.1084 18.1136 8.2428 17.9792 8.4084 17.9792H15.9084C16.074 17.9792 16.2084 18.1136 16.2084 18.2792V20.0792C16.2084 20.2448 16.074 20.3792 15.9084 20.3792Z"
                                            fill="#C76FF2"/>
                                        <path
                                            d="M14.8602 23.9425H9.46016C9.29456 23.9425 9.16016 23.8081 9.16016 23.6425V21.8425C9.16016 21.6769 9.29456 21.5425 9.46016 21.5425H14.8602C15.0258 21.5425 15.1602 21.6769 15.1602 21.8425V23.6425C15.1602 23.8081 15.0261 23.9425 14.8602 23.9425Z"
                                            fill="#59497A"/>
                                    </svg>
                                </div>
                                <div>
                                    <h5>{translationObject.popup.skape.title}</h5>
                                    <p>{translationObject.popup.skape.descp}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;
