import React from "react";

function Modalpopup(props) {
    const {currentQuestionIndex, questions} = props;

    let popupContent = questions[currentQuestionIndex].popup_content;

    return (
        <div className="modal fade" id="formuemodal" tabIndex="-1" aria-labelledby="formueModalLabel"
             aria-hidden="true">
            <div
                className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg modal-fullscreen-md-down">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body" dangerouslySetInnerHTML={{__html: popupContent}}></div>
                </div>
            </div>
        </div>
    )
}

export default Modalpopup
