import React from "react";
import ResultsEmailScreen from "./ResultsEmailScreen";
import ResultsFinalScreen from "./ResultsFinalScreen";
import {getFinalScore} from '../helpers/getFinalScore';

const Results = (props) => {
    const {
        questions,
        mainCategories,
        averageQuizScore,
        sendDataToAPI,
        showFinalScreen,
        answersFromAPIResponse,
        lang,
        answers
    } = props;

    document.body.classList.remove('is-loaded');

    if (!showFinalScreen) {
        return <ResultsEmailScreen
            score={getFinalScore(answers)}
            scoreString={''}
            sendDataToAPI={sendDataToAPI}
            lang={lang}
        />
    } else {
        return <ResultsFinalScreen
            answersFromAPIResponse={answersFromAPIResponse}
            questions={questions}
            mainCategories={mainCategories}
            lang={lang}
            averageQuizScore={averageQuizScore}

        />
    }
}
export default Results;