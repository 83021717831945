import React from "react";

export const getFinalScore = (answers) => {
    let irelevantAnswers = answers.filter(function (e) {
        return e.answer === null;
    }).length
    let correctAnswers = answers.filter(function (e) {
        return e.answer === true;
    }).length

    return (correctAnswers / (answers.length - irelevantAnswers) * 100).toFixed(0);
}