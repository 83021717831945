import React from "react";
import UserAnswer from "./UserAnswer";

const Question = (props) => {

    const {category, question} = props.quesiton;

    return (
        <div className="row chat__dialog flex-column">
            <div className="col">
                <div className="pb-3">
                    <div className="chat-item chat-item--q">
                        <div className="chat-item__cat">
                            {category}
                        </div>
                        <div className="chat-item__text">
                            {question}
                        </div>
                    </div>
                </div>
            </div>
            <UserAnswer userAnswer={props.userAnswer}/>
        </div>
    )
}

export default Question;