import React from "react";
import { Link } from 'react-router-dom';

const Header = (props) => {
    const {questionsLength, currentQuestionIndex, showBackButton, prevQuestion, quizProgress, lang} = props
    return (
        <header>
            <div
                className="container-fluid d-flex h-100 flex-row h-100 justify-content-between align-items-center">
                <div className="back-outer" style={styles}>
                    <a onClick={prevQuestion} className={(!showBackButton) ? 'inactive back' : 'back'}>
                        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="28px"
                             viewBox="0 0 24 24" width="28px" fill="#FFFFFF">
                            <rect fill="none" height="24" width="24"/>
                            <path
                                d="M9.7,18.3L9.7,18.3c0.39-0.39,0.39-1.02,0-1.41L5.83,13H21c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5.83l3.88-3.88 c0.39-0.39,0.39-1.02,0-1.41l0,0c-0.39-0.39-1.02-0.39-1.41,0L2.7,11.3c-0.39,0.39-0.39,1.02,0,1.41l5.59,5.59 C8.68,18.68,9.32,18.68,9.7,18.3z"/>
                        </svg>
                    </a>
                </div>
                <a className="logo-outer" href={'https://formue.'+ lang} target={'_blank'} >
                    <img className="logo" src="/images/logo.svg" alt="logo"/>
                </a>
                {(() => {
                    if (currentQuestionIndex !== undefined) {
                        return <div className="text-counter">
                            <small>{currentQuestionIndex + 1 + ' / ' + questionsLength}</small></div>
                    }
                })()}
            </div>
            <div id="progress" className="progress">
                <div className="progress__bar" style={{width: quizProgress + '%'}}></div>
            </div>
        </header>
    )
}

const styles = {
    minWidth: 42
};

export default Header;
